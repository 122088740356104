<template>
  <CModal
      color="primary"
      title="Зміна координат"
      :show.sync="showGeo"
      size="lg"
      add-content-classes="srl"
  >
    <CInput
        placeholder="Широта"
        @keyup="getMarkerToMap(geo.latitude,geo.longitude);"
        v-model="geo.latitude"
    ></CInput>
    <CInput
        placeholder="Довгота"
        @keyup="getMarkerToMap(geo.latitude,geo.longitude);"
        v-model="geo.longitude"
    ></CInput>
    <template v-if="geo.longitude !== '' && geo.latitude !== ''" class="justify-content-center">
      <GmapMap
          :center="center"
          :map-type-id="mapTypeId"
          style="max-width: 800px; height: 450px"
          :zoom="15"
          id='map'
          @click='changePositionMapMark'
      >
        <GmapMarker
            v-for="(item, index) in markers"
            :key="index"
            :position="item.position"
            :clickable="true"
            :draggable="true"
        />
      </GmapMap>
    </template>
    <template #footer>
      <CButton
          color="secondary"
          @click="removeGeo();"
      >
        Відмінити
      </CButton>
      <CButton
          color="primary"
          @click="saveMarkerToMap();"
      >
        Зберегти зміни
      </CButton>
    </template>
  </CModal>

</template>

<script>
export default {
  name: "ModalChangeCoordinate",
  props: {
    showGeo: {
      type: Boolean,
      required: true,
      default: false
    },
    changeGeo: {
      type: Object,
      required: true,
      default: null
    }
  },

  data: () => {
    return {
      center: {lat: 51.867287, lng: 33.480327},
      mapTypeId: "terrain",
      markers: [
        {
          position: {lat: 51.867287, lng: 33.480327}
        },
      ],
      geo: {
        latitude: '',
        longitude: ''
      }

    }
  },

  watch: {
    showGeo(val) {
      if (val) this.geo = this.changeGeo;
    }
  },

  methods: {
    getMarkerToMap(lat, lng) {
      this.markers[0].position.lat = Number(lat);
      this.markers[0].position.lng = Number(lng);
      this.center.lat = Number(lat);
      this.center.lng = Number(lng);
    },

    changePositionMapMark(event) {
      this.geo.latitude = event.latLng.lat();
      this.geo.longitude = event.latLng.lng();
      this.markers[0].position.lat = Number(this.geo.latitude);
      this.markers[0].position.lng = Number(this.geo.longitude);
      this.center.lat = Number(this.geo.latitude);
      this.center.lng = Number(this.geo.longitude);
    },

    removeGeo() {
      this.geo.latitude = "";
      this.geo.longitude = "";
      // this.getMarkerToMap(this.geo.latitude, this.geo.longitude);
      this.showGeo = !this.showGeo;
      this.$emit('close');
    },

    saveMarkerToMap() {
      if (!confirm("Ви справді бажаєте змінити координати бранча?")) {
        return
      }
      this.$emit('saveChangeGeo', this.geo);
      // this.getMarkerToMap(this.geo.latitude, this.geo.longitude);
      // this.geo.latitude = this.geo.latitude;
      // this.geo.longitude = this.geo.longitude;
      this.showGeo = !this.showGeo;
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>