<template>
  <CModal
      color="primary"
      title="Керівник ІТ РП"
      :show.sync="showHeadITRP"
      size="lg"
      addContentClasses="srl"
  >
    <template #body-wrapper>
      <div class="px-3">
        <!--                        <CAlert v-if="message.show_modal" style="font-size: 12px;" show-->
        <!--                                color="danger">{{ message.failed_message_modal }}</CAlert>-->


        <CCardHeader class="mx-0 px-0">
          <CRow>
            <CAlert color="danger" v-if="!$v.chief_it_rp.login.required" class="form-text errorText w-100">Виберіть
              керівника ІТ РП!
            </CAlert>

            <CCol>
              <h4>Виберіть керівника ІТ РП:
                <CSpinner v-if="!changeHeadITRP.length" class="align-middle "
                          color="primary" size="sm"/>
              </h4>
            </CCol>
            <CCol class="text-right">
              <CButton
                  color="secondary"
                  @click="showChangeHeader=true"
              >Змінити
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <template v-if="showChangeHeader">
          <multiselect
              placeholder="Зробіть вибір"
              class="mb-2"
              :options="changeHeadITRP"
              :allow-empty="false"
              :show-labels="false"
              required
              label="display"
              v-model="chief_it_rp"
              @input="$v.chief_it_rp.login.$touch(), $emit('saveCheifItRp', chief_it_rp)"
          />
        </template>
        <!--        @input="getHeaderName()"-->
        <CInput
            :disabled="true"
            type="text"
            placeholder="МРУ"
            v-model="chief_it_rp.mrm"
        >
        </CInput>
        <CInput
            :disabled="true"
            type="text"
            placeholder="Ім'я Керівника ІТ РП"
            v-model="chief_it_rp.name"
        >
        </CInput>
        <CInput
            :disabled="true"
            type="text"
            placeholder="login Керівника ІТ РП"
            v-model="chief_it_rp.login"
            :class=" {
                      'error': $v.chief_it_rp.login.$error
                    }"
        >
        </CInput>


      </div>
    </template>
    <template #footer>
      <CButton
          color="primary"
          class="px-4"
          :disabled="loadingBtn"
          @click="setHeaderITRP"
      >
        <CSpinner v-if="loadingBtn" color="default" class="align-items-center" size="sm"/>
        <span v-if="updateTender"> Оновити</span>
        <span v-else> Підтвердити</span>
      </CButton>
      <!--      <template v-else>-->
      <!--        <CButton-->
      <!--            v-if="is_edit"-->
      <!--            color="primary"-->
      <!--            class="px-4"-->
      <!--            @click="setHeaderITRP"-->
      <!--        >-->
      <!--          Оновити-->
      <!--        </CButton>-->
      <!--        <CButton-->
      <!--            v-else-->
      <!--            @click="setHeaderITRP"-->
      <!--            color="primary">-->
      <!--          Підтвердити-->
      <!--        </CButton>-->
      <!--      </template>-->

    </template>
  </CModal>

</template>

<script>
import axios from "axios";
import Multiselect from 'vue-multiselect'
import {required} from "vuelidate/lib/validators";
import {can} from "@/rules/permissions";

export default {
  name: "ModalChangeHeadITRP",
  components: {Multiselect},
  props: {
    showHeadITRP: {
      type: Boolean,
      required: true,
      default: false
    },
    updateTender: {
      type: Boolean,
      required: true,
      default: false
    },
    isCheifItRp: {
      type: Object,
      required: true,
      default: null
    }
  },
  data: () => {
    return {
      changeHeadITRP: [],

      chief_it_rp: {
        mrm: '',
        login: '',
        name: ''
      },

      loadingBtn: false,
      showChangeHeader: false,
    }
  },
  validations: {
    chief_it_rp: {
      name: {
        required
      },
      login: {
        required
      }
    }
  },
  watch: {
    showHeadITRP(val) {
      if (!val) return;

      if (!Object.keys(this.isCheifItRp).length) this.getHeaderInfo();
      if (Object.keys(this.isCheifItRp).length) this.chief_it_rp = this.isCheifItRp;

      this.getHeaderAll();
      this.loadingBtn = false;
    }
  },
  methods: {
    can,
    getHeaderAll() {
      let self = this;
      // self.loaderBranch.headerNameITRP = true;
      axios.get('/api/staff/chiefs')
          .then(function (response) {

            let chiefs = []
            for (let i = 0; i < response.data.length; i++) {
              let chief = response.data[i];
              chief.display = chief.name + " (" + chief.login + ")";
              chiefs.push(chief);
            }

            self.changeHeadITRP = chiefs;
            // self.loaderBranch.headerNameITRP = false;
          })
          .catch(function () {
            // self.loaderBranch.headerNameITRP = false;

          });
    },

    getHeaderInfo() {
      let self = this;
      // self.loaderBranch.headerNameITRP = true;
      axios.get('/api/staff/chiefs/info')
          .then(function (response) {
            // self.loaderBranch.headerNameITRP = false;
            self.chief_it_rp.name = response.data.name;
            self.chief_it_rp.login = response.data.login;
            self.chief_it_rp.mrm = response.data.mrm;
            // self.order.chief_it_rp = self.chief_it_rp.login;

            console.log(response.data)
            // self.getHeaderName()
          })
          .catch(function () {
            // self.loaderBranch.headerNameITRP = false;
          });
    },


    // getHeaderName() {
    //   this.order.chief_it_rp = this.chief_it_rp.login;
    //   // let self = this;
    //   // self.loaderBranch.headerNameITRP = true;
    //   // if (self.order.chief_it_rp === null) {
    //   //   self.headerITRPName = "";
    //   //   return;
    //   // }
    //   // axios.get('/api/staff/chiefs/' + self.order.chief_it_rp + '/name')
    //   //   .then(function (response) {
    //   //     self.loaderBranch.headerNameITRP = false;
    //   //     self.headerITRPName = response.data;
    //   //   })
    // },


    setHeaderITRP() {
      //if (can('bank_user')) return;
      console.log(this.chief_it_rp)
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loadingBtn = true;
        this.$emit('saveTender', this.chief_it_rp)
        this.loadingBtn = false;
        this.showChangeHeader = false;
      }
      //
      // if (this.order.chief_it_rp !== "") {
      //   this.message.show_modal = false;
      //   this.loadingBtn = true;
      //   this.changeHeadItRPModal = false;
      //   if (this.is_edit) {
      //     this.updateTender();
      //     return;
      //   }
      //   this.createTender();
      // }
      // if (this.order.chief_it_rp === "") {
      //   this.message.show_modal = true;
      //   this.loadingBtn = false;
      //   return;
      // }
    },
  }
}
</script>

<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #cb203d !important;
  border-style: solid !important;
  border-width: 2px !important;

}
</style>