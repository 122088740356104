<template>
  <CModal
    title="Зміна адресу"
    color="primary"
    :show.sync="showEditAddress"
    size="lg"
    addContentClasses="srl"
  >
    <template v-if="branches === 'b'">
      <CCardHeader>
        Країни
        <CSpinner v-if="!getMultiselectData.Country.length" color="primary" size="sm"/>
      </CCardHeader>
      <multiselect
        track-by="id"
        :show-labels="false"
        placeholder="Виберіть країну"
        :options="getMultiselectData.Country"
        v-model="address.Country"
        label="name_uan"
        @input="getRegion()"
      >
      </multiselect>
    </template>
    <CCardHeader>
      Області
      <CSpinner v-if="!getRegions.length" color="primary" size="sm"/>
    </CCardHeader>

    <multiselect
      track-by="code"
      placeholder="Виберіть область"
      :show-labels="false"
      :options="getRegions"
      v-model="address.Region"
      label="desc"
      @input="changeDistrict()"
      :disabled="branches === 'b' ? !address.Country ||  address.Country === null: false"
    >
    </multiselect>
    <!--                            raion-->
    <template v-if="disabled.isDisabledRegionsOrTown">
      <CCardHeader>
        Район або місто
        <CSpinner v-if="getSpinner.Region" color="primary"
                  size="sm"/>
      </CCardHeader>
      <multiselect
        :show-labels="false"
        track-by="code"
        placeholder="Виберіть район або місто"
        :options="getMultiselectData.District"
        v-model="address.District"
        label="desc"
        :custom-label="nameTypeWithDesc"
        @input="changeCity()"
        :disabled="!address.Region"
      >
      </multiselect>
    </template>

    <!--                            town-->
    <template v-if="disabled.isDisabledTown">
      <CCardHeader>
        Місто або село
        <CSpinner v-if="getSpinner.City" color="primary" size="sm"/>
      </CCardHeader>
      <multiselect
        track-by="code"
        :show-labels="false"
        placeholder="Виберіть місто або село"
        :options="getMultiselectData.City"
        v-model="address.City"
        label="desc"
        :custom-label="nameTypeWithDesc"
        @input="changeStreet()"
        :disabled="disabled.isDisabledRegionsOrTown ? !address.District || address.District  === null:  !address.Region || address.Region  === null"
      >
      </multiselect>
    </template>
    <!--                            STREET-->
    <CCardHeader>
      Вулиця
      <CSpinner v-if="getSpinner.Street" color="primary" size="sm"/>
    </CCardHeader>
    <multiselect
      track-by="code"
      :show-labels="false"
      placeholder="Виберіть вулицю"
      :options="getMultiselectData.Street"
      v-model="address.Street"
      label="desc"
      :custom-label="nameTypeWithDesc"
      :disabled="address.Region ? address.Region.desc !== 'Київ' ? !address.District : false : true"
    >
    </multiselect>
    <CCardHeader>
      Будинок
    </CCardHeader>
    <CInput
      v-model="address.House"
      placeholder="Введіть будинок"
    >
    </CInput>
    <CCardHeader>
      Корпус
    </CCardHeader>
    <CInput
      v-model="address.HouseCorps"
      placeholder="Введіть корпус"
    >
    </CInput>
    <template #footer>
      <CButton @click="saveChanges()" color="primary">
        Вірно
      </CButton>
    </template>
  </CModal>
</template>

<script>
import axios from "axios";
import Multiselect from 'vue-multiselect'

export default {
  name: "ModalEditAddress",
  components: {
    Multiselect,
  },
  props: {
    showEditAddress: {
      type: Boolean,
      required: true,
      default: false
    },
    branches: {
      type: String,
      required: false,
      default: ''
    },
    currentAddress: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => {
    return {
      disabled: {
        isDisabledTown: true,
        isDisabledRegionsOrTown: true,
      },


      address: {
        Country: "",
        Region: "",
        District: "",
        Street: "",
        City: "",
        House: "",
        HouseCorps: "",
      },

      getSpinner: {
        Region: false,
        City: false,
        Street: false,
      },

      getMultiselectData: {
        Country: [{
          alpha_2: "RW",
          alpha_3: "RWA",
          id: "RW1",
          name_eng: "Rwanda",
          name_rus: "Руанда",
          name_uan: "Руанда"
        },],
        District: [],
        City: [],
        Street: [],
      },
      getRegions: [],
      branch_address: '',
      geo: {
        latitude: '',
        longitude: ''
      },
      state_id: '',
    }
  },

  // created() {
  //   this.getRegion()
  //   if (this.branches !== 'b') this.getCountry();
  // },

  watch: {
    showEditAddress(val) {

      this.getClear();

      if (!val) return this.$emit('close');


      if (val) {
        if (this.currentAddress !== null) {

          this.address = Object.assign({}, this.currentAddress);
          if (this.branches === 'b') this.getCountry();
          this.getRegion();
          this.changeDistrict();
          this.changeCity();
          this.changeStreet();
        }
        if (this.branches !== 'b') {
          return this.getRegion();
        }
        this.getCountry()
      }
    }
  },

  methods: {
    saveChanges() {
      // this.branch_address = this.changeBranchAddress();
      if (this.branches !== 'b') {
        this.getGeoCustom();
      }
      let saveBranch = {
        // branch_address: this.branch_address,
        branches: this.branches,
        address: Object.assign({}, this.address),
        state_id: this.state_id
      }


      this.$emit('okBranchAddress', saveBranch);
      this.$emit('close');
      this.getClear()
      // this.showEditAddress = false;
    },


    getCountry() {
      let self = this;
      axios.get('/api/address/countries').then(function (response) {
        self.getMultiselectData.Country = response.data.country;
      }).catch(function () {
      })
    },


    getClear() {
      for (let item in this.address) {
        this.address[item] = ""
      }
      for (let item in this.getMultiselectData) {
        this.getMultiselectData[item] = []
      }
    },

    getRegion() {
      let self = this;
      // self.clear_address()
      let id = "UA40773";
      if (this.branches === 'b') {
        id = self.address.Country.id
      }
      axios.get(`/api/address/region/${id}`).then(function (response) {
        if (response.data.address) {
          return self.getRegions = response.data.address;
        }
        self.getRegions = [];

      }).catch(function () {
        self.getRegions = [];
      })
    },

    changeDistrict() {
      let self = this;
      self.state_id = self.address.Region.code
      if (self.address.Region.name_type === "м") {
        self.address.District = "";
        self.address.City = "";
        self.address.Street = "";
        self.disabled.isDisabledRegionsOrTown = false;
        self.disabled.isDisabledTown = false;
        self.getStreet(self.address.Region.code)
        return
      }
      self.getSpinner.Region = true
      self.disabled.isDisabledRegionsOrTown = true;
      axios.get('/api/address/district/' + self.address.Region.code)
        .then(function (response) {
          self.getMultiselectData.District = response.data.address;
          self.getSpinner.Region = false;
        }).catch(function () {
        self.getMultiselectData.District = [];
      })
    },

    changeCity() {
      let self = this;
      if (self.address.District.name_type === "м") {
        self.getStreet(self.address.District.code)
        self.disabled.isDisabledTown = false;
        return
      }
      self.getSpinner.City = true
      self.disabled.isDisabledTown = true;
      axios.get('/api/address/city/' + self.address.District.code)
        .then(function (response) {
          self.getMultiselectData.City = response.data.address;
          self.getSpinner.City = false;
          if (self.address.City !== null) {
            self.showSelectedCity = true;
          } else {
            self.showSelectedCity = false;
            self.changeStreet();
          }
        }).catch(function () {
        self.getMultiselectData.City = [];
      })
    },

    changeStreet() {
      let street = null
      if (this.address.City === null) {
        street = this.address.District;
      } else {
        street = this.address.City
      }
      let self = this;
      self.getSpinner.Street = true
      axios.get('/api/address/street/' + street.code)
        .then(function (response) {
          self.getMultiselectData.Street = response.data.address;
          self.getSpinner.Street = false;
        }).catch(function () {
        self.getMultiselectData.Street = [];
      })
    },

    getStreet(street_code) {
      let self = this;
      self.getSpinner.Street = true;
      axios.get('/api/address/street/' + street_code)
        .then(function (response) {
          self.getMultiselectData.Street = response.data.address;
          self.getSpinner.Street = false;
        }).catch(function () {
        self.getMultiselectData.Street = [];
      })
    },


    nameTypeWithDesc({name_type, desc}) {
      return `${name_type}. ${desc}`
    },

    changeBranchAddress() {
      let adr = '';
      if (this.branches === 'b') {
        adr = this.address.Country.name_uan.valueOf()
      }
      if (this.address.Region) {
        adr += `${this.address.Region.desc.valueOf()} ${this.address.Region.name_type.valueOf()}.`
      }
      if (this.address.District) {
        adr += `, ${this.address.District.desc.valueOf()} ${this.address.District.name_type.valueOf()}.`
      }
      if (this.address.City) {
        adr += `${this.address.City.name_type.valueOf()}. ${this.address.City.desc.valueOf()}`
      }
      if (this.address.Street) {
        adr += `${this.address.Street.name_type.valueOf()}. ${this.address.Street.desc.valueOf()}`
      }
      if (this.address.House) {
        adr += adr + ', ' + this.address.House.valueOf()
      }
      if (this.address.HouseCorps) {
        adr += `, Кор. ${this.address.HouseCorps.valueOf()}`
      }

      return adr;
    },

    getGeoCustom() {
      let self = this;
      let url = '/api/address/geo/street/' + self.address.Street.code + '/building/' + self.address.House
      if (self.address.HouseCorps !== '') {
        url = '/api/address/geo/street/' + self.address.Street.code + '/building/' + self.address.House + '?housingId=' + self.address.HouseCorps
      }
      axios.get(url)
        .then(function (response) {
          self.geo.latitude = response.data.geo.latitude;
          self.geo.longitude = response.data.geo.longitude;
          this.$emit('okGeoLocation', this.geo);
          // self.getMarkerToMap(self.geo.latitude, self.geo.longitude);
        })
        .catch(function () {

        })
    },


  }
}
</script>

<style scoped>
.card-header {
  border-width: 0;
}
</style>
