<template>
  <CRow v-if="can('bank_user')" class="w-100 justify-content-center">
    <CCard class="w-75">
      <CCardHeader>
        <div class="card-header-action">
          <h2 v-if="is_edit">Редагування заявки</h2>
          <h2 v-else>Створення заявки</h2>
        </div>
        <CAlert v-if="message.show_verify" data-cy="msg_verify" style="font-size: 12px;" show color="danger">
          {{ message.failed_message_wrong }}
        </CAlert>
        <CAlert v-if="errors.length" style="font-size: 12px;" data-cy="msg_error" show color="danger">
          <ul style="list-style-type: none" class="p-0 m-0">
            <li v-for="err in errors" :key="err">
              {{ err }}
            </li>
          </ul>
        </CAlert>
      </CCardHeader>
      <CCardBody>
        <CForm
            :was-validated="wasValidated"
            novalidate
        >
          <CCardGroup>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.order.consumer_type.$error}">
                <CCardHeader class="pl-0 pt-0 border-0">
                  Вид каналу
                </CCardHeader>
                <multiselect
                    data-cy="consumer_type"
                    :show-labels="false"
                    placeholder="Зробіть вибір"
                    invalid-feedback="Виберіть вид каналу"
                    :options="Object.values(consumer_types)"
                    v-model="order.consumer_type"
                    @change="$v.order.consumer_type.$touch()"
                    :class="{ 'error': $v.order.consumer_type.$error}"
                ></multiselect>
                <small v-if="!$v.order.consumer_type.required" class="form-text errorText w-100">Необхідно
                  вказати вид
                  каналу.</small>
              </div>
              <div class="form-item" :class="{'errorInput': $v.order.channel_purpose.$error}">
                <CCardHeader class="pl-0 border-0">
                  Призначення
                </CCardHeader>
                <multiselect
                    data-cy="channel_purpose"
                    :show-labels="false"
                    placeholder="Зробіть вибір"
                    invalid-feedback="Виберіть призначення"
                    :options="channel_purpose_select"
                    v-model="order.channel_purpose"
                    @change="$v.order.channel_purpose.$touch()"
                    :class="{ 'error': $v.order.channel_purpose.$error}"
                ></multiselect>
                <small v-if="!$v.order.channel_purpose.required" class="form-text errorText w-100">Необхідно
                  вказати
                  призначення каналу.</small>
              </div>
              <div class="form-item" :class="{'errorInput': $v.order.connect_type.$error}">
                <CCardHeader class="pl-0 border-0">
                  Тип каналу
                </CCardHeader>
                <multiselect
                    data-cy="connect_type"
                    required
                    :show-labels="false"
                    placeholder="Зробіть вибір"
                    invalid-feedback="Виберіть тип каналу"
                    class="float-left pr-0"
                    :options="['КПД L2','Інтернет','Інше']"
                    @change="showBranchBAddress(order.connect_type), $v.order.connect_type.$touch()"
                    v-model="order.connect_type"
                    :class=" {
                      'error': $v.order.connect_type.$error
                    }"
                ></multiselect>
                <small v-if="!$v.order.connect_type.required" class="form-text errorText w-100">Необхідно
                  заповнити тип
                  каналу.</small>
              </div>
              <div class="form-item" :class="{'errorInput': $v.new_connect_type.$error}">
                <CInput
                    data-cy="new_connect_type"
                    class="mt-3"
                    placeholder="Введіть інший тип каналу"
                    v-if="order.connect_type === 'Інше'"
                    v-model="new_connect_type"
                    @change="$v.new_connect_type.$touch()"
                    :class=" {
                      'error': $v.new_connect_type.$error
                    }"
                >
                </CInput>
                <small v-if="!$v.new_connect_type.required" class="form-text errorText w-100">Необхідно
                  заповнити інший
                  тип
                  каналу.</small>
              </div>


            </CCard>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.order.reason_type.$error}">

                <CCardHeader class="pl-0 pt-0 border-0">
                  Причина тендеру
                </CCardHeader>
                <multiselect
                    data-cy="reason_type"
                    required
                    :show-labels="false"
                    placeholder="Зробіть вибір"
                    invalid-feedback="Виберіть причину"
                    @change="showChangeReason(order.reason_type), $v.order.reason_type.$touch()"
                    :options="['Передислокація', 'Заміна провайдера', 'Резервний канал','Нове підключення','Інше']"
                    v-model="order.reason_type"
                    :class="{ 'error': $v.order.reason_type.$error}"
                ></multiselect>
                <small v-if="!$v.order.reason_type.required" class="form-text errorText w-100">Необхідно
                  вказати причина
                  тендеру.</small>
              </div>
              <div class="form-item" :class="{'errorInput': $v.new_reason_type.$error}">
                <CInput
                    data-cy="new_reason_type"
                    class="mt-3"
                    placeholder="Введіть іншу причину тендеру"
                    v-if="order.reason_type === 'Інше'"
                    v-model="new_reason_type"
                    @change="$v.new_reason_type.$touch()"
                    :class=" {
                      'error': $v.new_reason_type.$error
                    }"
                >
                </CInput>
                <small v-if="!$v.new_reason_type.required" class="form-text errorText w-100">Необхідно
                  вказати іншу
                  причину тендеру.</small>
              </div>
              <div class="form-item" :class="{'errorInput': $v.order.channel_speed.$error}">

                <CCardHeader class="pl-0 border-0">
                  Швидкість, мбіт/с
                </CCardHeader>
                <multiselect
                    data-cy="channel_speed"
                    required
                    :show-labels="false"
                    placeholder="Зробіть вибір"
                    class="float-left pr-0"
                    invalid-feedback="Введіть швидкість підключення"
                    :options="[1,2,5,10,20,50,100,200,'Інше']"
                    @change="showChangeSpeed(order.channel_speed), $v.order.channel_speed.$touch()"
                    v-model="order.channel_speed"
                    :class=" {
                      'error': $v.order.channel_speed.$error
                    }"
                ></multiselect>
                <small v-if="!$v.order.channel_speed.required" class="form-text errorText w-100">Необхідно
                  вказати
                  швидкість.</small>
              </div>
              <div class="form-item" :class="{'errorInput': $v.new_channel_speed.$error}">

                <CInput
                    data-cy="new_channel_speed"
                    class="pt-3"
                    v-if="order.channel_speed === 'Інше'"
                    placeholder="Введіть іншу швидкість"
                    v-model="new_channel_speed"
                    @change="$v.new_channel_speed.$touch()"
                    :class=" {
                      'error': $v.new_channel_speed.$error
                    }"
                >
                </CInput>
                <small v-if="!$v.new_channel_speed.required" class="form-text errorText w-100">Необхідно
                  вказати іншу
                  швидкість.</small>
              </div>
              <!--                            <label class="pt-3">Бажана дата підключення</label>-->
              <template v-if="is_edit">
                <!--                                <CInput-->
                <!--                                    :placeholder="order.date_connection"-->
                <!--                                    :disabled="true"-->
                <!--                                >-->
                <!--                                </CInput>-->
                <div class="form-item" :class="{'errorInput': $v.new_date_connection.$error}">

                  <CCardHeader class="pl-0 border-0">
                    Введіть нову дату підключення
                  </CCardHeader>
                  <vc-date-picker
                      data-cy="new_date_connection"
                      required
                      locale="uk"
                      :popover="{placement: 'bottom', visibility: 'click'}"
                      v-model="new_date_connection"
                      color="gray"
                      :min-date="new Date(Date.now() +(11 * 86400000))"
                      mode="date"
                      :class=" {
                      'error d-block': $v.new_date_connection.$error
                    }"
                  >
                    <template v-slot="{inputValue, inputEvent}">
                      <input
                          class="w-100 py-2"
                          :value="inputValue"
                          v-on="inputEvent"
                          masks="DD.MM.YYYY"
                      />
                    </template>
                  </vc-date-picker>
                  <small v-if="!$v.new_date_connection.required" class="form-text errorText w-100">Необхідно
                    вказати
                    нову дату підключення.</small>
                </div>
              </template>
              <template v-else>
                <div class="form-item" :class="{'errorInput': $v.order.date_connection.$error}">

                  <CCardHeader class="pl-0 border-0">
                    Бажана дата підключення
                  </CCardHeader>
                  <vc-date-picker
                      data-cy="date_connection"
                      required
                      locale="uk"
                      :popover="{placement: 'bottom', visibility: 'click'}"
                      v-model='order.date_connection'
                      color="gray"
                      :min-date="new Date(Date.now() +(11 * 86400000))"
                      @change="$v.order.date_connection.$touch()"
                      :class=" {
                      'error d-block': $v.order.date_connection.$error
                    }"
                  />
                  <small v-if="!$v.order.date_connection.required" class="form-text errorText w-100">Необхідно
                    вказати
                    бажану дату підключення.</small></div>
              </template>
            </CCard>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.order.channel_required.$error}">
                <label>Вимоги до каналу <span class="text-right text-secondary text-sm">({{
                    lengthChannel_required
                  }}/{{ $v.order.channel_required.$params.maxLength.max }})</span>
                  <p class="text-information">Інформація доступна для провайдера!</p>
                </label>
                <CTextarea data-cy="channel_required" placeholder="Не обов'язкове поле" cols="20"
                           rows="3"
                           :maxlength="$v.order.channel_required.$params.maxLength.max +1 "
                           @change="$v.order.channel_required.$touch()"
                           :class=" {
                              'error': $v.order.channel_required.$error
                            }"
                           v-model="order.channel_required"></CTextarea>
                <small v-if="!$v.order.channel_required.maxLength" class="form-text errorText w-100">Максимальна
                  кількість символів
                  {{ $v.order.channel_required.$params.maxLength.max }}!</small>
              </div>

              <div class="form-item" :class="{'errorInput': $v.order.extra.$error}">
                <label>Коментар (для працівників) <span class="text-right text-secondary text-sm">({{
                    lengthExtra
                  }}/{{ $v.order.extra.$params.maxLength.max }})</span>
                  <p class="text-information">Внутрішня інформація. Доступна тільки працівникам банку!</p>
                </label>
                <CTextarea data-cy="extra" placeholder="Не обов'язкове поле" cols="20" rows="3"
                           :maxlength="$v.order.extra.$params.maxLength.max + 1"
                           @change="$v.order.extra.$touch()"
                           :class=" {
                              'error': $v.order.extra.$error
                            }"
                           v-model="order.extra"></CTextarea>
                <small v-if="!$v.order.extra.maxLength" class="form-text errorText w-100">Максимальна
                  кількість символів
                  {{ $v.order.extra.$params.maxLength.max }}!</small>
              </div>
            </CCard>
          </CCardGroup>
          <hr>
          <CCardGroup>
            <CCard class="p-3 border-0">
              <template v-if="order.consumer_type === consumer_types.external ">
                <div class="form-item" :class="{'errorInput': $v.order.merchant.$error}">

                  <CCardHeader class="m-0 p-0 pb-2 border-0">
                    Мерчант
                  </CCardHeader>
                  <CInput
                      data-cy="merchant"
                      placeholder="Довжина повинна бути 8 символи"
                      type="text"
                      required
                      invalid-feedback="Такого мерчанта не існує"
                      @keyup="checkMerchant(order.merchant), $v.order.merchant.$touch()"
                      v-model.trim="order.merchant"
                      @change="$v.order.merchant.$touch()"
                      :class=" {
                        'error': $v.order.merchant.$error
                      }"
                  >
                  </CInput>
                  <small v-if="!$v.order.merchant.required" class="form-text errorText w-100">Необхідно
                    вказати
                    мерчанта.</small>
                  <small v-if="!$v.order.merchant.minLength || !$v.order.merchant.maxLength"
                         class="form-text errorText w-100">Довжина повинна бути 8 символи.</small>
                </div>
              </template>
              <div class="form-item" :class="{'errorInput': $v.order.branch_a.$error}">
                <CCardHeader class="p-0 m-0 pb-2 border-0">
                  Бранч А
                  <CSpinner v-if="loaderBranch.branch_a_address" color="primary" size="sm"/>
                </CCardHeader>
                <CInput
                    data-cy="branch_a"
                    id="branchA"
                    placeholder="Довжина повинна бути 4 символи"
                    type="text"
                    required
                    :disabled="disabled.isDisabledBranchA"
                    invalid-feedback="Такого бранчу не існує"
                    @keyup="checkBranch(order.branch_a, 'branch_a_address')"
                    v-model.trim="order.branch_a"
                    @change="$v.order.branch_a.$touch()"
                    :class=" {
                      'error': $v.order.branch_a.$error
                    }"
                >
                </CInput>
                <small v-if="!$v.order.branch_a.required" class="form-text errorText w-100">Необхідно
                  вказати
                  бранч.</small>
                <small v-if="!$v.order.branch_a.minLength || !$v.order.branch_a.maxLength"
                       class="form-text errorText w-100">Довжина повинна бути 4 символи.</small>
              </div>
              <CButton
                  data-cy="change_branch_a"
                  v-if="order.consumer_type ===  consumer_types.external"
                  color="success"
                  @click="changeBranchA"
              >
                Змінити бранч
              </CButton>
              <div class="form-item">
                <CCardHeader class="p-0 m-0 pb-2 border-0">
                  Регіон сповіщення
                </CCardHeader>
                <CInput
                    disabled
                    v-model="order.region"
                ></CInput>
              </div>


              <div class="form-item" :class="{'errorInput': $v.order.branch_a_address.$error}">
                <CTextarea
                    data-cy="address_branch_A"
                    label="Адреса Точка А"
                    cols="15"
                    rows="3"
                    :disabled="true"
                    v-model="order.branch_a_address"
                >
                </CTextarea>
                <small v-if="!$v.order.branch_a_address.required" class="form-text errorText w-100">Необхідно
                  вказати
                  адрес точк А.</small>
              </div>
              <CButton
                  data-cy="change_address_branch_A"
                  v-if="order.branch_a !== ''"
                  color="success"
                  @click="openModalChangeAddress('a')"
              >Змінити адресу
              </CButton>

            </CCard>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.order.coordinates.$error}">
                <CCardHeader class="p-0 m-0 pb-2 border-0">
                  Координати
                  <CSpinner v-if="loaderBranch.branch_a_address" color="primary" size="sm"/>
                </CCardHeader>
                <CInput
                    :disabled="true"
                    v-model="order.coordinates"
                    @change="$v.order.coordinates.$touch()"
                    :class=" {
                      'error': $v.order.coordinates.$error
                    }"
                >
                </CInput>
                <small v-if="!$v.order.coordinates.required" class="form-text errorText w-100">Необхідно
                  координати.</small>
              </div>
              <template v-if="geo.longitude !== '' && geo.latitude !== ''">
                <div>
                  <GmapMap :center="center" :map-type-id="mapTypeId" :zoom="18">
                    <GmapMarker
                        v-for="(item, index) in markers"
                        :key="index"
                        :position="item.position"
                        @click="center = item.position"
                    />
                  </GmapMap>
                </div>
                <CButton
                    color="success"
                    class="w-100 mt-2"
                    @click="changeGeoLocation"
                >Змінити координати
                </CButton>
              </template>
            </CCard>
            <CCard class="p-3 border-0">
              <div class="form-item" :class="{'errorInput': $v.order.branch_b.$error}">
                <CCardHeader class="p-0 m-0 pb-2 border-0">
                  Бранч Б
                  <CSpinner v-if="loaderBranch.branch_b_address" color="primary" size="sm"/>
                </CCardHeader>
                <CInput
                    data-cy="branch_b"
                    placeholder="Довжина повинна бути 4 символи"
                    type="text"
                    invalid-feedback="Такого каналу не існує"
                    :disabled="disabled.isDisabledBranchB"
                    @keyup="checkBranchB(order.branch_b)"
                    v-model.trim="order.branch_b"
                    @change="$v.order.branch_b.$touch()"
                    :class=" {
                      'error': $v.order.branch_b.$error
                    }"
                >
                </CInput>
                <small v-if="!$v.order.branch_b.required" class="form-text errorText w-100">Необхідно
                  вказати
                  бранч.</small>
                <small v-if="!$v.order.branch_b.minLength || !$v.order.branch_b.maxLength"
                       class="form-text errorText w-100">Довжина повинна бути 4 символи.</small>
              </div>
              <!--                          <span v-if="order.connect_type === 'КПД L2' || order.connect_type === 'Інше' || order.connect_type !== 'Інтернет'">-->
              <template v-if="order.connect_type !== 'Інтернет' && order.connect_type !== ''">
                <div class="form-item" :class="{'errorInput': $v.branch_b_address.$error}">
                  <multiselect
                      data-cy="select_addres_branch_b"
                      track-by="id"
                      :show-labels="false"
                      placeholder="Виберіть Адресу"
                      :options="changeAddressB"
                      v-model="branch_b_address"
                      label="name"
                      @input="changeAddressITB"
                  >
                  </multiselect>
                  <small v-if="!$v.branch_b_address.required" class="form-text errorText w-100">Необхідно
                    вибрати
                    адрес точк Б.</small>
                </div>
              </template>
              <div class="form-item" :class="{'errorInput': $v.order.branch_b_address.$error}">
                <CTextarea
                    data-cy="address_branch_b"
                    label="Адреса Точки Б"
                    :disabled="true"
                    cols="15"
                    rows="3"
                    v-model="order.branch_b_address"
                >
                </CTextarea>
                <small v-if="!$v.order.branch_b_address.required" class="form-text errorText w-100">Необхідно
                  вказати
                  адрес точк Б.</small>
              </div>
              <CButton
                  data-cy="change_branch_b"
                  v-if="order.connect_type === 'КПД L2' || order.connect_type === 'Інше'"
                  color="success"
                  @click="openModalChangeAddress('b')"
              >Змінити адресу
              </CButton>
            </CCard>
          </CCardGroup>
          <hr>
          <CAlert v-if="message.show" style="font-size: 12px;" show color="danger">
            {{ message.failed_message_images_upload }}
          </CAlert>
          <CAlert v-if="message.show_delete" style="font-size: 12px;" show color="danger">
            {{ message.failed_message_images_delete }}
          </CAlert>
          <drop-zone-file @targetFile="readBlob">
            <template v-slot:errorMessage>
              <CAlert v-if="imageErrors.length" style="font-size: 12px;" data-cy="msg_error" show color="danger">
                <ul style="list-style-type: none" class="p-0 m-0">
                  <li v-for="err in imageErrors" :key="err">
                    {{ err }}
                  </li>
                </ul>
              </CAlert>
            </template>
            <template v-slot:showFiles>
              <CRow>
                <template v-if="is_edit">
                  <div col="6" class="mr-2" v-for="(img, key) in orderImages" :key="key">
                    <div class="imageBorder">
                      <a class="btn btn-sm btn-danger" @click="removeImgFromServer(img,key)">&#10005;</a>
                      <img
                          :src="baseURL + '/api/orders/'+ $route.params.id +'/icons/' + img"
                          alt="" class="preview">
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div col="6" class="mr-2" v-for="(image, key) in order.icons" :key="key">
                    <div class="imageBorder">
                      <a class="btn btn-sm btn-danger" @click="removeImg(key)">&#10005;</a>
                      <img :src="image" class="preview" :ref="'image'"/>
                    </div>
                  </div>
                </template>
              </CRow>
            </template>
          </drop-zone-file>
        </CForm>
      </CCardBody>

      <CCardFooter>
        <CRow>
          <CCol col="12" sm="7" xl="10" md="8" lg="9">
            <CButton
                data-cy="go_back"
                color="secondary"
                class="px-4 mr-1"
                @click="goBack"
            >
              Назад
            </CButton>
          </CCol>
          <CCol col="12" sm="5" xl="2" md="4" lg="3" class="text-right">
            <CButton
                data-cy="send_to_validation"
                color="primary"
                class="px-4"
                :disabled="loadingElm.loading"
                @click="validationForm"
            >
              <CSpinner v-if="loadingElm.loading" color="default" class="align-items-center" size="sm"/>
              <span v-if="is_edit"> Оновити</span>
              <span v-else> Створити</span>
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>

    </CCard>

    <modal-change-address
        :showEditAddress="modalDataChangeAddress.show"
        :branches="modalDataChangeAddress.branch"
        :currentAddress="currentAdrress[modalDataChangeAddress.branch]"
        @close="openModalChangeAddress('')"
        @okBranchAddress="changeAddress"
        @okGeoLocation="saveCustomGeo"
    />

    <modal-change-coordinate
        :showGeo="modalDataChangeCoordinate.show"
        :changeGeo="modalDataChangeCoordinate.geo"
        @saveChangeGeo="saveCustomGeo"
        @close="modalDataChangeCoordinate.show = false"
    />

    <modal-change-head-i-t-r-p
        :show-head-i-t-r-p="modalDataChangeHeadITRP.show"
        :updateTender="modalDataChangeHeadITRP.updateTender"
        :isCheifItRp="modalDataChangeHeadITRP.cheif_it_rp"
        @saveTender="saveTender"
        @saveCheifItRp="saveCheifItRp"
    />

    <modal-any-information :show-any-information-modal="alertModalShow" @close="alertModal" color="info">
      <template v-slot:body>
        <h4>Ви бажаєте покинути сторінку?</h4>
        <p>(Дані які ви заповнили не збережуться!)</p>
      </template>
      <template v-slot:footer>
        <CButton color="secondary" ref="confirmLeaveBtn">Покинути</CButton>
        <CButton color="info" ref="cancelLeaveBtn">Залишитись</CButton>
      </template>
    </modal-any-information>
  </CRow>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import {gmapApi} from 'vue2-google-maps'
import timeConvertMixin from '../../mixins/timeConvertMixin'
import ModalChangeAddress from '../Components/Modal/ModalEditAddress'
import ModalChangeCoordinate from '../Components/Modal/ModalChangeCoordinate'
import ModalChangeHeadITRP from '../Components/Modal/ModalChangeHeadITRP'
import {required, minLength, numeric, maxLength, requiredIf} from 'vuelidate/lib/validators'
import DropZoneFile from "../Components/DropZone/dropZoneFile.vue";
import ModalAnyInformation from "~/Components/Modal/ModalAnyInformation.vue";
import {routerGoToName} from "@/utils/routerUtils";
import {can} from "@/rules/permissions";

export default {
  mixins: [timeConvertMixin],
  components: {
    ModalAnyInformation,
    DropZoneFile,
    Multiselect,
    ModalChangeAddress,
    ModalChangeCoordinate,
    ModalChangeHeadITRP
  },
  beforeRouteLeave(to, from, next) {
    if (this.okLeave) return next();
    const isObjectHasValue = this.hasFilledKey(this.order);
    if (!isObjectHasValue) return next();
    this.alertModal(true);

    const confirmLeave = () => {
      this.alertModal();
      this.okLeave = true; // Дозволити перехід на іншу сторінку
      next();
    };
    const cancelLeave = () => {
      this.alertModal();
      next(false);
    };
    const leaveButton = () => {
      confirmLeave();
      cleanup();
    };
    const cancelButton = () => {
      cancelLeave();
      cleanup();
    };
    const cleanup = () => {
      this.$refs.confirmLeaveBtn.removeEventListener('click', leaveButton);
      this.$refs.cancelLeaveBtn.removeEventListener('click', cancelButton);
    };

    this.$nextTick(() => {
      this.$refs.confirmLeaveBtn.addEventListener('click', leaveButton);
      this.$refs.cancelLeaveBtn.addEventListener('click', cancelButton);
    });

    next(false);
  },
  name: "TenderCreate",
  data: () => {
    return {
      alertModalShow: false,
      okLeave: false,
      consumer_types: {
        external: 'Зовнішній (АТМ/ТСО/РОS)',
        internal: 'Внутрішній (відділення)'
      },

      turnRedaction: false,
      btnChangeBranchAdresA: false,
      // merchant_stop_find:false,
      maps: null,

      headerITRPName: "",
      changeHeadITRP: [],

      branch_b_address: "",
      changeAddressB: [
        {
          id: 0,
          name: "",
          branch: "",
          address: "",
          created_at: "",
        },
      ],
      changeHeadItRPModal: false,
      wasValidated: false,
      baseURL: '',
      orderImages: [],
      is_edit: false,
      order: {
        merchant: '',
        channel_purpose: "",
        connect_type: "",
        branch_a: "",
        branch_a_address: "",
        branch_b: "",
        branch_b_address: "",
        consumer_type: "",
        channel_speed: null,
        date_connection: null,
        channel_required: "",
        coordinates: "",
        icons: [],
        extra: "",
        state_id: "",
        region: "",
        chief_it_rp: "",
        reason_type: "",
      },

      unix_data_conection: null,
      new_connect_type: "",
      new_reason_type: "",
      new_date_connection: "",
      new_channel_speed: null,
      geo: {
        latitude: "",
        longitude: "",
      },
      mode: 'single',
      loadingElm: {
        loading: false,
        loadingRegions: false,
        loadingDistrictCity: false,
        loadingStreet: false,
        loadingHeaderIT: false,
      },
      changeAddressPointA: false,

      showSelectedCity: false,
      loaderBranch: {
        branch_a_address: false,
        branch_b_address: false,
        headerNameITRP: false,
      },
      errConfirmBranch: {
        branch_a_address: true,
        branch_b_address: true
      },
      center: {lat: 51.867287, lng: 33.480327},
      mapTypeId: "terrain",
      markers: [
        {
          position: {lat: 51.867287, lng: 33.480327}
        },
      ],
      disabled: {
        isDisabledBranchB: true,
        isDisabledBranchA: false,
      },
      message: {
        show_verify: false,
        failed_message_images_upload: "Сталася помилка, картинку не видалося, загрузити",
        failed_message_images_delete: "Сталася помилка, картинку не видалено, спробуйте пізніше",
        failed_message_wrong: "Щось пішло не так... Спробуйте пізніше!",
      },
      errors: [],
      imageErrors: [],
      merchant: {
        showBtn: false,
      },
      channel_purpose_select: ['Звичайний', 'МКЦ', 'Агрегуючий', 'Магістральний'],


      modalDataChangeAddress: {
        show: false,
        branch: ''
      },
      modalDataChangeCoordinate: {
        show: false,
        geo: {
          latitude: "",
          longitude: "",
        },
      },
      modalDataChangeHeadITRP: {
        show: false,
        updateTender: false,
        cheif_it_rp: {}
      },
      currentAdrress: {
        a: null,
        b: null
      }

    }
  },
  computed: {
    google: gmapApi,
    lengthExtra: function () {
      return this.order.extra.length;
    },
    lengthChannel_required: function () {
      return this.order.channel_required.length;
    }
  },
  created() {
    if (this.$route.params.id) {
      this.is_edit = true;
      this.modalDataChangeHeadITRP.updateTender = true;
      this.baseURL = axios.defaults.baseURL;
      this.getTenderByID();
    }

    this.turnRedaction = new URLSearchParams(window.location.search).get("update");
  },
  watch: {
    "order.connect_type": function (current, previous) {
      if (!previous && this.is_edit) {
        return;
      }
      return this.showBranchBAddress(this.order.connect_type);
    },
    "order.consumer_type": function (current, previous) {
      if (!previous && this.is_edit) {
        return;
      }
      return this.showMerchant(current, previous);
    },
  },
  validations: {
    order: {
      merchant: {
        minLength: minLength(8),
        maxLength: maxLength(8),
        required: requiredIf(function () {
          // return this.order.consumer_type === this.consumer_types.external;
          return this.order.consumer_type === 'Зовнішній (АТМ/ТСО/РОS)';
        })
      },
      channel_purpose: {
        required,
        minLength: minLength(1)
      },
      connect_type: {
        required,
        minLength: minLength(1)
      },
      branch_a: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4)
      },
      branch_a_address: {
        required,
        minLength: minLength(5)
      },
      branch_b: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4)
      },
      branch_b_address: {
        required,
        minLength: minLength(5)
      },
      consumer_type: {
        required
      },
      channel_speed: {
        required,
      },
      date_connection: {
        required,
      },
      channel_required: {
        maxLength: maxLength(3000)
      },
      coordinates: {
        required,
        minLength: minLength(10),
      },
      extra: {maxLength: maxLength(3000)},
      state_id: {
        required: requiredIf(function () {
          return !this.is_edit;
        }),
        minLength: minLength(4)
      },
      // chief_it_rp: {
      //   required:requiredIf(function(){
      //     return
      //   }),
      //   minLength: minLength(8),
      //   maxLength: maxLength(15)
      // },
      reason_type: {required},
    },
    new_connect_type: {
      minLength: minLength(1),
      required: requiredIf(function () {
        return this.order.connect_type === "Інше"
      })
    },
    new_reason_type: {
      minLength: minLength(1),
      required: requiredIf(function () {
        return this.order.reason_type === "Інше"
      })
    },
    new_channel_speed: {
      minLength: minLength(1),
      maxLength: maxLength(10),
      numeric,
      required: requiredIf(function () {
        return this.order.channel_speed === "Інше"
      })
    },
    new_date_connection: {
      required: requiredIf(function () {
        return this.is_edit;
      })
    },
    branch_b_address: {
      required: requiredIf(function () {
        return this.order.connect_type !== 'Інтернет' && this.order.connect_type !== ''
      })
    }
  },
  methods: {
    can,
    alertModal(show = false) {
      this.alertModalShow = show;
    },
    hasFilledKey(obj) {
      return Object.values(obj).some(value => {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) return this.hasFilledKey(value)
        if (Array.isArray(value) && !value.length) return Boolean(false)

        return Boolean(value);
      });
    },
    openModalChangeAddress(branch) {
      this.modalDataChangeAddress.branch = branch;
      if (!branch) return this.modalDataChangeAddress.show = false;
      this.modalDataChangeAddress.show = true;
    },
    changeAddress(val) {
      console.log(val)
      this.currentAdrress[val.branches] = val.address;
      let brAddress = this.changeBranchAddress(this.currentAdrress[val.branches], val.branches)
      console.log(brAddress)
      if (val.branches === 'b') return this.order.branch_b_address = brAddress;

      this.order.state_id = val.state_id;
      this.order.region = val.address.Region.desc || this.order.region;
      //console.log("addr region", val.address.Region.desc)
      this.order.branch_a_address = brAddress;
    },

    changeBranchAddress(obj, branches) {
      let adr = '';
      if (branches === 'b') {
        adr = obj.Country.name_uan.valueOf()
      }
      console.log(adr);
      if (obj.Region) {
        adr += `${obj.Region.desc.valueOf()} ${obj.Region.name_type.valueOf()}.`
      }

      if (obj.District && obj.District.name_type === "м") {
        adr += `, ${obj.District.name_type.valueOf()}. ${obj.District.desc.valueOf()}`
      } else if (obj.District) {
        adr += `, ${obj.District.desc.valueOf()} ${obj.District.name_type.valueOf()}.`
      }

      if (obj.City) {
        adr += `, ${obj.City.name_type.valueOf()}. ${obj.City.desc.valueOf()}`
      }
      if (obj.Street) {
        adr += `, ${obj.Street.name_type.valueOf()}. ${obj.Street.desc.valueOf()}`
      }
      if (obj.House) {
        adr += ', ' + obj.House.valueOf()
      }
      if (obj.HouseCorps) {
        adr += `, Кор. ${obj.HouseCorps.valueOf()}`
      }

      return adr;
    },


    saveCustomGeo(val) {
      this.geo.latitude = val.latitude;
      this.geo.longitude = val.longitude;
      this.getMarkerToMap(this.geo.latitude, this.geo.longitude);
    },

    saveTender(val) {
      this.order.chief_it_rp = val.login;
      this.modalDataChangeHeadITRP.cheif_it_rp = val;
      this.modalDataChangeHeadITRP.show = false;

      if (this.modalDataChangeHeadITRP.updateTender) return this.updateTender();

      this.createTender();
    },

    saveCheifItRp(val) {
      this.order.chief_it_rp = val.login;
      this.modalDataChangeHeadITRP.cheif_it_rp = val;
    },

    showMerchant(current, previous) {
      console.log(`current: ${current}, previous ${previous}`)
      this.channel_purpose_select = ['Звичайний', 'МКЦ', 'Агрегуючий', 'Магістральний'];
      if (current === this.consumer_types.external) {
        if (this.order.channel_purpose !== 'Звичайний') {
          this.order.channel_purpose = ''
        }
        //this.merchant.showBtn = true;
        this.disabled.isDisabledBranchA = true;
        this.channel_purpose_select = ['Звичайний'];
        return;
      }

      this.order.merchant = '';
      //this.merchant.showBtn = false;
      this.disabled.isDisabledBranchA = false;
      this.checkBranch(this.order.branch_a, 'branch_a_address');
    },

    goBack() {
      // if (this.$route.params.id) return this.$router.push({path: `/tenders/${this.$route.params.id}`});
      if (this.$route.params.id) return routerGoToName('TendersPreview', 'params', this.$route.params.id);
      // this.$router.push({path: `/tenders`});
      routerGoToName('Tenders');
    },
    addAndRemoveValue(array, value) {
      array.push(value); // Додаємо значення до масиву

      setTimeout(() => {
        const index = array.indexOf(value); // Знаходимо індекс значення в масиві
        if (index !== -1) {
          array.splice(index, 1); // Видаляємо значення з масиву
        }
      }, 3000); // Час через який видаляється значення (3000 мс = 3 секунди)
    },
    readBlob(e) {
      let self = this;
      let filess = e;

      for (let i = 0; i < filess.length; i++) {
        let f = filess[i]; // FileList object

        // Перевірка, чи є файл зображенням
        if (!f.type.startsWith('image/')) {
          this.addAndRemoveValue(this.imageErrors, 'Помилка: Обраний файл не є зображенням');
          continue; // Пропустити обробку некоректного файлу і перейти до наступного
        }

        let fileSizeMB = f.size / (1024 * 1024);
        if (fileSizeMB > 5) {
          this.addAndRemoveValue(this.imageErrors, 'Помилка: Розмір файла перевищує 5 МБ');
          continue; // Пропустити обробку файлу з неприпустимим розміром і перейти до наступного
        }

        let reader = new FileReader();
        // Завертання у замикання для збереження інформації про файл
        reader.onload = (function (file) {
          return function (e) {
            let binaryData = e.target.result;
            let icon = "data:" + file.type + ";base64," + window.btoa(binaryData);

            if (self.is_edit) {
              self.addIcon(icon);
            }
            self.order.icons.push(icon);
          };
        })(f);
        reader.readAsBinaryString(f);
      }
    },

    addIcon(icon) {
      let self = this;
      axios.post('/api/orders/' + self.$route.params.id + '/icons', {"icon": icon})
          .then(function (response) {
            let icon = response.data.toString()
            self.orderImages.push(icon);
          }).catch(() => self.addAndRemoveValue(self.imageErrors, 'Помилка: Фотографії/картинки не змогли відправити на сервер'))
    },

    removeImg(key) {
      this.order.icons.splice(key, 1);
    },

    removeImgFromServer(img, key) {
      let self = this;
      axios.delete('/api/orders/' + self.$route.params.id + '/icons/' + img)
          .then(function () {
            self.orderImages.splice(key, 1);
          }).catch(() => self.addAndRemoveValue(self.imageErrors, 'Помилка: Не змогли видалити фотографію/картинку із сервера'))
    },

    createTender() {
      if (this.new_connect_type !== '') {
        this.order.connect_type = this.new_connect_type;
      }

      if (this.new_reason_type !== '') {
        this.order.reason_type = this.new_reason_type;
      }
      this.order.date_connection = Number(this.timeUnix(this.order.date_connection))
      this.order.coordinates = this.geo.latitude + ', ' + this.geo.longitude
      if (this.order.channel_speed === "Інше") {
        this.order.channel_speed = Number(this.new_channel_speed);
      }
      if (this.order.connect_type === "Інше") {
        this.order.connect_type = this.new_connect_type;
      }

      if (this.order.state_id === "UA40722") {
        this.order.state_id = "UA14155";
      }

      let TenderLink = "";
      let self = this;
      axios.post('/api/orders', self.order)
          .then(function (response) {
            self.okLeave = true;
            self.loadingElm.loadingHeaderIT = false;
            TenderLink = self.tenderLinks(response.data);
            self.$router.push({path: TenderLink});
          })
          .catch(function (error) {
            self.message.failed_message_wrong = error.response.data.message ? error.response.data.message : "Щось пішло не так... Спробуйте пізніше!";
            self.message.show_verify = true;
            self.loadingElm.loadingHeaderIT = false;
          });
    },

    validationForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.modalDataChangeHeadITRP.show = !this.modalDataChangeHeadITRP.show;
      }

    },

    updateTender() {
      if (this.new_connect_type !== '') {
        this.order.connect_type = this.new_connect_type;
      }
      this.order.date_connection = Number(this.unix_data_conection);

      if (this.new_date_connection !== '') {
        this.order.date_connection = Number(this.timeUnix(this.new_date_connection));
      }
      if (this.new_reason_type !== '') {
        this.order.reason_type = this.new_reason_type;
      }
      if (this.order.channel_speed === "Інше") {
        this.order.channel_speed = Number(this.new_channel_speed);
      }
      this.order.coordinates = this.geo.latitude + ', ' + this.geo.longitude;
      let TenderLink = "";

      if (this.order.state_id === "UA40722") {
        this.order.state_id = "UA14155";
      }

      let self = this;
      let link = `/api/orders/${this.$route.params.id}`;
      if (self.turnRedaction) {
        link += `?update=true`
      }
      axios.put(link, self.order)
          .then(function (response) {
            self.okLeave = true;
            TenderLink = self.tenderLinks(response.data);
            self.$router.push({path: TenderLink})
          })
          .catch(function (error) {
            self.message.failed_message_wrong = error.response.data.message ? error.response.data.message : "Щось пішло не так... Спробуйте пізніше!";
            self.message.show_verify = true;
            self.loadingElm.loadingHeaderIT = false;
          });
    },

    tenderLinks(id) {
      return `/tenders/${id.toString()}`
    },

    confirmBranch(val, what) {
      if (val === '') {
        return
      }
      if (val.length === 4) {
        if (what === 'a') {
          return this.errConfirmBranch.branch_a_address
        }
        if (what === 'b') {
          return this.errConfirmBranch.branch_b_address
        }
      }
      return false
    },

    checkBranch(branch_name, branch_type) {

      let self = this;
      self.errConfirmBranch[branch_type] = true;

      if (branch_name.length !== 4) {
        if (this.btnChangeBranchAdresA) {
          return
        }
        self.order[branch_type] = "";
        if (branch_type !== 'branch_b_address') {
          self.geo.latitude = "";
          self.geo.longitude = "";
          self.order.coordinates = "";
        }
        return
      }
      if (branch_type !== 'branch_b_address' && self.order.consumer_type === this.consumer_types.external) {
        self.getGeoBranch(branch_name);
        return
      }
      self.loaderBranch[branch_type] = true;
      axios.get('/api/rp/address/' + branch_name)
          .then(function (response) {
            if (!self.btnChangeBranchAdresA) {
              self.order[branch_type] = response.data.full_address;
            }
            self.loaderBranch[branch_type] = false;
            if (branch_type !== 'branch_b_address') {
              self.getGeoBranch(branch_name);
            }
            self.order.region = response.data.region
          })
          .catch(function () {
            if (!self.btnChangeBranchAdresA) {
              self.errConfirmBranch[branch_type] = false;
              self.order[branch_type] = "";
              self.loaderBranch[branch_type] = false;
            }
            self.loaderBranch[branch_type] = false;
          });
    },

    changeBranchA() {
      this.disabled.isDisabledBranchA = !this.disabled.isDisabledBranchA;
      this.btnChangeBranchAdresA = !this.btnChangeBranchAdresA;
    },

    checkMerchant(merchant) {
      let self = this;
      if (merchant.length !== 8) {
        return
      }
      self.disabled.isDisabledBranchA = false;

      axios.get(`/api/atm/address/${merchant}`).then(function (response) {
        self.order.branch_a = response.data.branch_a;
        self.order.branch_a_address = response.data.branch_a_address;
        self.geo.latitude = response.data.latitude;
        self.geo.longitude = response.data.longitude;
        self.getMarkerToMap(self.geo.latitude, self.geo.longitude);
        self.disabled.isDisabledBranchA = true;
        self.order.region = response.data.region
        // self.merchant_stop_find=true;
        self.checkBranch(self.order.branch_a, 'branch_a_address')
      });
    },

    checkBranchB(branch_name) {
      let self = this;
      self.errConfirmBranch.branch_b_address = true;
      if (branch_name.length !== 4) {
        self.order.branch_b_address = "";
        self.changeAddressB = [];
        self.branch_b_address = "";
        return
      }
      self.loaderBranch.branch_b_address = true;
      axios.get('/api/channels/nodes?branch=' + branch_name)
          .then(function (response) {
            if (self.order.connect_type === 'КПД L2' || self.order.connect_type === 'Інше' || self.order.connect_type != 'Інтернет') {
              self.changeAddressB = response.data;
            }
            if (self.order.connect_type !== 'КПД L2' && self.order.connect_type !== 'Інше') {
              self.order.branch_b_address = response.data[0].address;
            }
            self.loaderBranch.branch_b_address = false;
          })
          .catch(function () {
            self.errConfirmBranch.branch_b_address = false;
            self.loaderBranch.branch_b_address = false;
            self.order.branch_b_address = "";
            self.loaderBranch.branch_b_address = false;
          });
    },

    changeAddressITB() {
      if (this.branch_b_address !== null) {
        this.order.branch_b_address = this.branch_b_address.address;
        return;
      }
      this.order.branch_b_address = "";
    },

    getGeoBranch(val) {
      let self = this;
      axios.get('/api/address/geo/' + val)
          .then(function (response) {
            response.data.address.forEach(function (v) {
              if (v.lang === "ENG") {
                v.addr_path.forEach(function (addr) {
                  if (addr.node_type_name === "area" || addr.node_type_name === "region") {
                    self.order.state_id = addr.code;
                  }
                  if (addr.node_type_name === "city" && addr.code === "UA40722") {
                    self.order.state_id = "UA14155"
                  }
                })
              }
            });
            if (!self.btnChangeBranchAdresA && !self.merchant.showBtn) {
              self.geo.latitude = response.data.geo.latitude;
              self.geo.longitude = response.data.geo.longitude;

              self.getMarkerToMap(self.geo.latitude, self.geo.longitude);
              return
            }

            if (self.btnChangeBranchAdresA && self.merchant.showBtn) {
              self.geo.latitude = response.data.geo.latitude;
              self.geo.longitude = response.data.geo.longitude;

              self.getMarkerToMap(self.geo.latitude, self.geo.longitude);
              return;
            }
          })
          .catch(function () {
            if (self.btnChangeBranchAdresA || self.merchant.showBtn) {
              return
            }
            self.geo.latitude = ""
            self.geo.longitude = "";
          })
    },

    showBranchBAddress(ConnectType) {
      if (ConnectType === 'Інтернет') {
        this.disabled.isDisabledBranchB = true;
        this.checkBranch('DNH0', 'branch_b_address')
        this.order.branch_b = 'DNH0';
        return
      }
      if (!ConnectType) {
        this.disabled.isDisabledBranchB = true;
        this.order.branch_b = '';
        this.order.branch_b_address = '';
        this.branch_b_address = '';
        return;
      }
      if (ConnectType !== 'Інше') {
        this.new_connect_type = ""
      }
      if (this.is_edit) {
        this.disabled.isDisabledBranchB = false;
        return;
      }
      this.disabled.isDisabledBranchB = false;
      this.order.branch_b = '';
      this.branch_b_address = '';
      this.order.branch_b_address = '';
    },

    showChangeSpeed(speed_connection) {
      if (speed_connection !== 'Інше') {
        this.new_channel_speed = null
        return
      }
      // this.order.channel_speed = this.new_channel_speed
    }
    ,

    showChangeReason(reason_type) {
      if (reason_type !== 'Інше') {
        this.new_reason_type = null
        return
      }
      // this.order.channel_speed = this.new_channel_speed
    },

    getMarkerToMap(lat, lng) {
      this.markers[0].position.lat = Number(lat);
      this.markers[0].position.lng = Number(lng);
      this.center.lat = Number(lat);
      this.center.lng = Number(lng);
      this.order.coordinates = lat + ', ' + lng;
    },

    getTenderByID() {
      let self = this;
      axios.get('/api/orders/' + this.$route.params.id)
          .then(function (response) {
            self.order = response.data;
            self.unix_data_conection = self.order.date_connection;
            self.order.icons = [];
            self.order.coordinates = self.order.coordinates.split(',')
            self.geo.latitude = Number(self.order.coordinates[0]);
            self.geo.longitude = Number(self.order.coordinates[1]);
            self.order.date_connection = self.timeConvertTender(self.order.date_connection)
            self.new_date_connection = new Date(self.timeConvertTenderV2(self.order.date_connection))
            self.getMarkerToMap(self.geo.latitude, self.geo.longitude);
            // self.checkBranchB(self.order.branch_b);
            self.getIcons();
            self.quaryd()
          });
    },

    quaryd() {
      this.turnRedaction = new URLSearchParams(window.location.search).get("update");
    },

    getIcons() {
      let self = this;
      axios.get('/api/orders/' + this.$route.params.id + '/icons')
          .then(function (response) {
            self.orderImages = response.data;
          }).catch((e) => self.addAndRemoveValue(self.imageErrors, `Помилка: Фотографії/картинки не змогливідобразити - ${e}`))
    },

    changeGeoLocation() {
      this.modalDataChangeCoordinate.geo.latitude = this.geo.latitude;
      this.modalDataChangeCoordinate.geo.longitude = this.geo.longitude;
      this.modalDataChangeCoordinate.show = !this.modalDataChangeCoordinate.show;
    },
  },
}
</script>
<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}
</style>
