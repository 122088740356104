import { render, staticRenderFns } from "./ModalChangeCoordinate.vue?vue&type=template&id=25fc2769&scoped=true&"
import script from "./ModalChangeCoordinate.vue?vue&type=script&lang=js&"
export * from "./ModalChangeCoordinate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25fc2769",
  null
  
)

export default component.exports